import { buildMetadata } from "@/utils/metadata";
import socioGrpcClient from "@/setup/socioGrpcClient";

const requests = socioGrpcClient.atmo_back.installations;
const grpcClient =
  socioGrpcClient.atmo_back.installations.ConstatControllerPromiseClient;

export const ConstatClient = {
  list: async (filters) => {
    const metadata = buildMetadata({
      filters,
    });
    const request = new requests.ConstatListRequest();
    const response = await grpcClient.utils.listAllObjects(request, metadata);
    return response;
  },
  create: async (constat) => {
    const request = socioGrpcClient.javascriptToRequest(
      requests.ConstatRequest,
      constat
    );
    const response = await grpcClient.create(request, {});
    return response.toObject();
  },
  update: async (constat) => {
    const request = socioGrpcClient.javascriptToRequest(
      requests.ConstatRequest,
      constat
    );
    const response = await grpcClient.update(request, {});
    return response.toObject();
  },
  bulkUpdate: async (constats) => {
    const request = new requests.ConstatBulkUpdateRequest();

    for (const constat of constats) {
      const constatRequest = socioGrpcClient.javascriptToRequest(
        requests.ConstatRequest,
        constat
      );
      request.addConstats(constatRequest);
    }
    const response = await grpcClient.bulkUpdate(request, {});

    return response.toObject().resultsList;
  },
  destroy: async (constatUuid) => {
    const data = {
      uuid: constatUuid,
    };
    const request = socioGrpcClient.javascriptToRequest(
      requests.ConstatDestroyRequest,
      data
    );
    const response = await grpcClient.destroy(request, {});
    return response.toObject();
  },
  bulkDestroy: async (constatsUuids) => {
    const data = {
      constatsUuids,
    };
    const request = socioGrpcClient.javascriptToRequest(
      requests.ConstatBulkDestroyRequest,
      data
    );
    const response = await grpcClient.bulkDestroy(request, {});
    return response.toObject();
  },
  detection: async (form) => {
    const request = new requests.DetectionRequest();

    for (const installation of form.installations) {
      const installationRequest = new requests.DetectionInstallationRequest();
      installationRequest.setInstallationUuid(installation.installationUuid);

      for (const parameter of installation.parameters) {
        const parameterRequest = new requests.DetectionParameterRequest();
        parameterRequest.setParameterUuid(parameter.parameterUuid);

        for (const sample of parameter.samples) {
          const sampleRequest = socioGrpcClient.javascriptToRequest(
            requests.DetectionSampleRequest,
            sample
          );
          parameterRequest.addSamples(sampleRequest);
        }

        installationRequest.addParameters(parameterRequest);
      }

      request.addInstallations(installationRequest);
    }

    const response = await grpcClient.detection(request, {});
    return response.toObject().constatsList;
  },
};
