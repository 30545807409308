import socioGrpcClient from "@/setup/socioGrpcClient";
const requests = socioGrpcClient.production_unit_lister.lister;
const grpcClient =
  socioGrpcClient.production_unit_lister.lister
    .FullProjectControllerPromiseClient;

export const ListerClient = {
  list: async (query, ordering) => {
    let filterObj = {};
    let metadata = {};

    if (query) filterObj = { ...query };
    if (ordering) filterObj["project_ordering"] = ordering;
    if (query || ordering) {
      metadata = {
        filters: JSON.stringify(filterObj),
      };
    }
    const request = new requests.FullProjectListRequest();
    return await grpcClient.utils.listAllObjects(request, metadata);
  },
};
