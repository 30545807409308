import { Document, DocumentType } from "@socotec.io/socio-vue-components";
import db from "@/db";
import { DocumentClient } from "@/api";
import { useinstallationPhotos } from "@/composables/installations/photos/useInstallationPhotos";

import { buildQueryset } from "@/utils/queryset";

const SET_DOCUMENT_COUNT = "SET_DOCUMENT_COUNT";
const SET_TAGS = "SET_TAGS";
const SET_IS_LOADING = "SET_IS_LOADING";
const SET_CURRENT_DOCUMENTS = "SET_CURRENT_DOCUMENTS";
const SET_CURRENT_IMAGES = "SET_CURRENT_IMAGES";
const ADD_IMAGE = "ADD_IMAGE";
const REMOVE_IMAGE = "REMOVE_IMAGE";

const state = {
  documentsCount: 0,
  documentTablePageSize: 10,
  selectedDocuments: [],
  tagsList: [],
  documentIsLoading: true,
  currentDocuments: [],
  currentImages: [],
};

const getters = {
  getDocument: () => async (documentUuid) => {
    return await db.documents.get(documentUuid);
  },
  getDocuments: (state) => {
    return Document.query()
      .orderBy("createdAt", "desc")
      .limit(state.documentTablePageSize)
      .get();
  },
  getImageDocuments: () => {
    const photoType = DocumentType.query().where("name", "PHOTO").get();
    return Document.query()
      .orderBy("createdAt", "desc")
      .where("docType", photoType.uuid)
      .get();
  },
  documentTypePhoto() {
    return DocumentType.query().where("name", "PHOTO").get();
  },
  getDocumentsByUuids: () => async (documentUuids) => {
    return await db.documents.where("uuid").anyOf(documentUuids).toArray();
  },
  documentsCount: (state) => {
    return state.documentsCount;
  },
  tagsNames: (state) => {
    return state.tagsList.map((tag) => tag.name);
  },
};

const actions = {
  async fetchDocumentTypes() {
    const documentTypes = await DocumentClient.listDocumentTypes();
    await DocumentType.insert({ data: documentTypes });
  },

  async fetchDocumentByUuid(_, documentUuid) {
    const document = await DocumentClient.retrieve(documentUuid);
    await Document.insert({ data: document });
    return document;
  },

  async fetchPictureByUuid({ commit }, uuid) {
    const picture = await DocumentClient.retrieve(uuid);
    await db.models.Document.createOrUpdate(picture);
    commit(ADD_IMAGE, picture);
    return picture;
  },
  async fetchInstallationImagesByUuids({ commit }, documentUuids) {
    commit(SET_IS_LOADING, true);
    const documents = await Promise.all(
      documentUuids.map(async (uuid) => await DocumentClient.retrieve(uuid))
    );
    for (const document of documents) {
      await db.models.Document.createOrUpdate(document);
    }
    commit(SET_CURRENT_IMAGES, documents);
    commit(SET_IS_LOADING, false);
    return documents;
  },
  async fetchDocuments({ commit }, { projectUuid, query }) {
    if (!window.navigator.onLine) return;
    commit(SET_IS_LOADING, true);
    const response = await DocumentClient.list({
      objectIds: [projectUuid],
      ...query,
    });
    for (const document of response.resultsList) {
      await db.models.Document.createOrUpdate(document);
    }
    commit(SET_DOCUMENT_COUNT, response.count);
    commit(SET_IS_LOADING, false);
    return await Document.create({ data: response.resultsList });
  },

  async filterDocuments(
    { commit, state },
    { queryAsObject, ordering, filterMapping }
  ) {
    while (state.documentIsLoading)
      await new Promise((r) => setTimeout(r, 200));

    commit(SET_IS_LOADING, true);
    const { queryset, count } = await buildQueryset(
      db.documents,
      queryAsObject,
      ordering,
      filterMapping
    );

    commit(SET_CURRENT_DOCUMENTS, await queryset.toArray());
    commit(SET_DOCUMENT_COUNT, count);
    commit(SET_IS_LOADING, false);
  },

  async getDocumentUrl(context, documentCustomUuid) {
    const documentUrl = await DocumentClient.retrieveDocumentUrl(
      documentCustomUuid
    );
    return documentUrl.documentUrl;
  },

  async getDocumentInObjectStorageUrl(context, documentUuid) {
    const { documentUrl } =
      await DocumentClient.retrieveDocumentInObjectStorageUrl(documentUuid);
    return documentUrl;
  },

  async uploadDocument(
    context,
    { file, fileName, document, documentCustom, callBack }
  ) {
    await DocumentClient.create(
      file,
      fileName,
      document,
      documentCustom,
      callBack
    );
  },

  async createManualDocument(context, document) {
    const response = await DocumentClient.createWithoutFile(document);
    await Document.insert({ data: response });
    await db.documents.add(response);
    return response;
  },

  async updateDocument(context, data) {
    try {
      const response = await DocumentClient.update(data);
      await Document.update({ data: response, where: response.uuid });
      await db.documents.update(response.uuid, response);
    } catch (error) {
      console.error(error);
      throw new Error();
    }
  },

  async updateMultipleDocuments(context, { data, documentUuids }) {
    const response = await DocumentClient.bulkUpdate(data, documentUuids);
    response.forEach((document) => {
      db.documents.update(document.uuid, document);
    });
    await Document.update({ data: response });
  },

  async removeInstallationPicture({ commit }, { installation, documentUuid }) {
    const { removePhotoFromInstallation } = useinstallationPhotos();

    const response = await DocumentClient.destroy(documentUuid);
    if (response.code === "SUCCESS") {
      await removePhotoFromInstallation(installation, documentUuid);
      await Document.delete(documentUuid);
      await db.documents.delete(documentUuid);
      commit(REMOVE_IMAGE, documentUuid);
    }
  },

  async removeDocument(context, documentUuid) {
    const response = await DocumentClient.destroy(documentUuid);
    if (response.code === "SUCCESS") {
      await Document.delete(documentUuid);
      await db.documents.delete(documentUuid);
    }
  },

  async fetchDocumentTags({ commit }, projectUuid) {
    try {
      const response = await DocumentClient.listTags({
        objectIds: [projectUuid],
      });
      commit(SET_TAGS, response);
      return response;
    } catch (error) {
      console.error(error);
      throw new Error();
    }
  },
};

const mutations = {
  [SET_DOCUMENT_COUNT]: (state, count) => {
    state.documentsCount = count;
  },
  [SET_TAGS]: (state, tags) => {
    state.tagsList = tags;
  },
  [SET_IS_LOADING]: (state, isLoading) => {
    state.documentIsLoading = isLoading;
  },
  [SET_CURRENT_IMAGES]: (state, images) => {
    state.currentImages = images;
  },
  [ADD_IMAGE]: (state, image) => {
    state.currentImages.push(image);
  },
  [REMOVE_IMAGE]: (state, imageUuid) => {
    state.currentImages = [
      ...state.currentImages.filter(({ uuid }) => uuid !== imageUuid),
    ];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
