import { MaterialClient } from "@/api";
import db from "@/db";
import { buildQueryset } from "@/utils/queryset";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

const state = {
  currentPreparation: null,
  materialList: [],
  totalMaterials: 0,
  materialIsLoading: false,
};

const getters = {};

const actions = {
  async fetchMaterials({ commit }) {
    if (!window.navigator.onLine) return;

    commit("SET_IS_LOADING", true);
    const lastMaterial = await db.materials
      .orderBy("syncUpdatedAt")
      .reverse()
      .first();
    const query = {
      page_size: 100,
      materials_ordering: "updated_at",
    };
    if (lastMaterial) {
      query.updated_at = dayjs
        .utc(lastMaterial.syncUpdatedAt)
        .format("YYYY-MM-DD:HH-mm-ss");
    }
    const response = await MaterialClient.list(query);
    db.transaction("rw", db.materials, async () => {
      for (const material of response) {
        db.models.Material.createOrUpdate(material);
      }
    });
    commit("SET_IS_LOADING", false);
    return response;
  },

  async filterMaterials(
    { commit, state },
    { queryAsObject, ordering, filterMapping }
  ) {
    while (state.materialIsLoading)
      await new Promise((r) => setTimeout(r, 200));
    commit("SET_IS_LOADING", true);
    const { queryset, count } = await buildQueryset(
      db.materials,
      queryAsObject,
      ordering,
      filterMapping
    );
    commit("SET_IS_LOADING", false);
    commit("SET_MATERIALS", await queryset.toArray());
    commit("SET_TOTAL_MATERIALS", count);
  },

  async searchMaterialsByBrandAndCategory(
    context,
    { brandName, categoryName }
  ) {
    const ret = await db.materials
      .filter(
        ({ brand, category, reference }) =>
          category.name === categoryName &&
          (brand.toLowerCase().includes(brandName.toLowerCase()) ||
            reference.toLowerCase().includes(brandName.toLowerCase()))
      )
      .toArray();
    return ret;
  },

  async updateMaterial(context, material) {
    const response = await MaterialClient.update(material);
    await db.materials.update(response.uuid, response);
    return response;
  },

  async setAllMaterials({ commit, state }) {
    while (state.sampleFlaskIsLoading)
      await new Promise((r) => setTimeout(r, 200));
    commit("SET_IS_LOADING", true);
    const materialsList = await db.materials.toArray();
    commit("SET_MATERIALS", materialsList);
    commit("SET_IS_LOADING", false);
  },

  async createMaterial({ commit }, material) {
    const response = await MaterialClient.create(material);
    await db.materials.add(response);
    commit("SET_TOTAL_MATERIALS", state.totalMaterials + 1);
    return response;
  },

  async deleteMaterial({ commit }, materialUuid) {
    await MaterialClient.destroy(materialUuid);
    await db.materials.delete(materialUuid);
    commit("REMOVE_MATERIAL", materialUuid);
    commit("SET_TOTAL_MATERIALS", state.totalMaterials - 1);
  },
};
const mutations = {
  SET_IS_LOADING: (state, loading) => (state.materialIsLoading = loading),
  REMOVE_MATERIAL: (state, materialUuid) => {
    state.materialList = state.materialList.filter(
      (material) => material.uuid !== materialUuid
    );
  },
  SET_MATERIALS: (state, materials) => (state.materialList = materials),
  SET_TOTAL_MATERIALS: (state, materialCount) =>
    (state.totalMaterials = materialCount),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
