import db from "@/db";
import store from "@/store";
import crudGenerator from "@/api/utils/offlineSync";

const {
  innerFetch,
  innerCreate,
  innerUpdate,
  innerBulkCreate,
  innerBulkUpdate,
} = crudGenerator(db.models.Vle);

const SET_VLE_LIST = "SET_VLE_LIST";
const BULK_ADD_VLE_LIST = "BULK_ADD_VLE_LIST";
const BULK_UPDATE_VLE_LIST = "BULK_UPDATE_VLE_LIST";
const UPDATE_VLE_LIST = "UPDATE_VLE_LIST";
const SET_IS_LOADING = "SET_IS_LOADING";

const state = {
  vleList: [],
  vleIsLoading: false,
};

const getters = {};

const actions = {
  async createVle({ commit, rootGetters }, form) {
    const vle = await innerCreate(form, rootGetters["projects/isOfflineState"]);
    await store.dispatch("installations/refreshLastUpdated");
    commit(UPDATE_VLE_LIST, vle);
    return vle;
  },

  async bulkCreateVle({ commit, rootGetters }, form) {
    const vles = await innerBulkCreate(
      form,
      rootGetters["projects/isOfflineState"]
    );

    commit(BULK_ADD_VLE_LIST, vles);
    await store.dispatch("installations/refreshLastUpdated");

    return vles;
  },

  async updateVle({ commit, rootGetters }, form) {
    const vle = await innerUpdate(form, rootGetters["projects/isOfflineState"]);
    commit(UPDATE_VLE_LIST, vle);
    return vle;
  },

  async bulkUpdateVles({ commit, rootGetters }, form) {
    const vles = await innerBulkUpdate(
      form,
      rootGetters["projects/isOfflineState"]
    );

    commit(BULK_UPDATE_VLE_LIST, vles);
    await store.dispatch("installations/refreshLastUpdated");

    return vles;
  },

  async fetchVles({ commit, rootGetters }, projectUuid) {
    const filters = {
      project: projectUuid,
    };
    const setIsLoading = (isLoading) => commit(SET_IS_LOADING, isLoading);
    await innerFetch(
      filters,
      rootGetters["projects/isOfflineState"],
      setIsLoading
    );
  },

  async filterVle({ state, commit }, { sumsUuids, configurationLinesUuids }) {
    while (state.vleIsLoading) await new Promise((r) => setTimeout(r, 200));
    commit(SET_IS_LOADING, true);
    const vles = await db.vles
      .filter(
        ({ configurationLine, sum }) =>
          sumsUuids.includes(sum) ||
          configurationLinesUuids.includes(configurationLine)
      )
      .toArray();

    for (const vle of vles) {
      await vle.setRelationships();
    }

    commit(SET_VLE_LIST, vles);
    commit(SET_IS_LOADING, false);
  },

  async retrieveVleBySumUuid(context, sumUuid) {
    const vle = await db.vles.get({ sum: sumUuid });
    await vle.setRelationships();
    return vle;
  },
};

const mutations = {
  [SET_VLE_LIST]: (state, vles) => {
    state.vleList = [...vles];
  },

  [BULK_ADD_VLE_LIST]: (state, vles) => {
    state.vleList = [...state.vleList, ...vles];
  },

  [BULK_UPDATE_VLE_LIST]: (state, vles) => {
    for (const vle of vles) {
      const vleIndex = state.vleList.findIndex(({ uuid }) => vle.uuid === uuid);
      state.vleList[vleIndex] = vle;
    }
    state.vleList = [...state.vleList];
  },

  [UPDATE_VLE_LIST]: (state, vle) => {
    const vleIndex = state.vleList.findIndex(({ uuid }) => vle.uuid === uuid);

    if (vleIndex < 0) {
      state.vleList.push(vle);
    } else {
      state.vleList[vleIndex] = vle;
      state.vleList = [...state.vleList];
    }
  },

  [SET_IS_LOADING]: (state, loading) => (state.vleIsLoading = loading),
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
