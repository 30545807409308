import BaseSchema from "./base";

class Document extends BaseSchema {
  static primaryKey = "uuid";
  static tableName = "documents";
  static fields = [
    "uuid",
    "createdAt",
    "updatedAt",
    "tagsList",
    "servicesRelatedNameList",
    "originId",
    "oldOriginId",
    "origin",
    "gedProjectId",
    "name",
    "source",
    "ref",
    "datetime",
    "version",
    "docType",
    "docStatus",
    "metaDatas",
    "relatedObjectsList",
    "isActive",
    "filename",
    "projects",
    // INFO - PH - 30/08/2022 - Front specific fields
    "isLoaded",
  ];

  static relatedFields = {
    projects: {
      m2m: true,
      tableName: "projects",
      relatedKey: "relatedObjectsList",
      owner: true,
    },
  };

  static dateFields = ["createdAt", "updatedAt", "datetime", "issue_date"];
}

export default Document;
