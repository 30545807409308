import db from "@/db";
import crudGenerator from "@/api/utils/offlineSync";
import { ConstatClient } from "@/api";

const {
  innerFetch,
  innerCreate,
  innerUpdate,
  innerBulkUpdate,
  innerDelete,
  innerBulkDelete,
} = crudGenerator(db.models.Constat);

const state = {
  constatsList: [],
  constatIsLoading: false,
};

const getters = {};
const SET_CONSTAT_LIST = "SET_CONSTAT_LIST";
const SET_IS_LOADING = "SET_IS_LOADING";
const SET_UPDATE_CONSTAT = "SET_UPDATE_CONSTAT";
const REMOVE_CONSTAT = "REMOVE_CONSTAT";
const REMOVE_MANY_CONSTAT = "REMOVE_MANY_CONSTAT";
const ADD_NEW_CONSTAT = "ADD_NEW_CONSTAT";
const SET_UPDATE_CONSTATS = "SET_UPDATE_CONSTATS";

const actions = {
  async fetchConstats({ commit, rootGetters }, filters = {}) {
    const setIsLoading = (isLoading) => commit(SET_IS_LOADING, isLoading);
    const res = await innerFetch(
      filters,
      rootGetters["project/isOfflineState"],
      setIsLoading
    );
    console.log(res);
    return res;
  },

  async filterConstat({ commit }, { query }) {
    const { normeName, constatWording } = query;
    commit(SET_IS_LOADING, true);

    const constatsList = await db.constats
      .filter((item) => {
        return (
          item.normeName.toLowerCase().includes(normeName.toLowerCase()) &&
          item.wording.toLowerCase().includes(constatWording.toLowerCase())
        );
      })
      .toArray();

    commit(SET_CONSTAT_LIST, constatsList);
    commit(SET_IS_LOADING, false);
  },

  async updateConstat({ commit, rootGetters }, form) {
    commit(SET_IS_LOADING, true);
    const constat = await innerUpdate(
      form,
      rootGetters["projects/isOfflineState"]
    );
    commit(SET_IS_LOADING, false);
    commit(SET_UPDATE_CONSTAT, constat);
    return constat;
  },

  async bulkUpdateConstat({ commit, rootGetters }, form) {
    commit(SET_IS_LOADING, true);
    const constats = await innerBulkUpdate(
      form,
      rootGetters["projects/isOfflineState"]
    );
    commit(SET_IS_LOADING, false);
    commit(SET_UPDATE_CONSTATS, constats);
  },

  async deleteConstat({ commit, rootGetters }, constatUuid) {
    commit(SET_IS_LOADING, true);
    innerDelete(
      constatUuid,
      rootGetters["projects/isOfflineState"],
      rootGetters["projects/currentProjectUuid"]
    );
    commit(REMOVE_CONSTAT, constatUuid);
    commit(SET_IS_LOADING, false);
  },
  async deleteBulkConstat({ commit, rootGetters }, affectedConstatsUuid) {
    commit(SET_IS_LOADING, true);
    innerBulkDelete(
      affectedConstatsUuid,
      rootGetters["projects/isOfflineState"],
      rootGetters["projects/currentProjectUuid"]
    );
    commit(SET_IS_LOADING, false);
    commit(REMOVE_MANY_CONSTAT, affectedConstatsUuid);
  },

  async createConstat({ commit, rootGetters }, form) {
    commit(SET_IS_LOADING, true);
    const constat = await innerCreate(
      form,
      rootGetters["projet/isOfflineState"]
    );
    commit(SET_IS_LOADING, false);
    commit(ADD_NEW_CONSTAT, constat);
    return constat;
  },

  async setAllConstats({ commit }) {
    commit(SET_IS_LOADING, true);
    const contats = await db.constats.toArray();
    commit(SET_CONSTAT_LIST, contats);
    commit(SET_IS_LOADING, false);
  },

  async detectConstats(context, form) {
    return await ConstatClient.detection(form);
  },
};

const mutations = {
  [SET_CONSTAT_LIST]: (state, resultsList) => {
    state.constatsList = resultsList;
  },
  [SET_IS_LOADING]: (state, constatIsLoading) => {
    state.constatIsLoading = constatIsLoading;
  },
  [SET_UPDATE_CONSTAT]: (state, constat) => {
    const constatIndex = state.constatsList.findIndex(
      ({ uuid }) => constat.uuid === uuid
    );

    if (constatIndex < 0) return;

    state.constatsList[constatIndex] = constat;
    state.constatsList = [...state.constatsList];
  },
  [SET_UPDATE_CONSTATS]: (state, constats) => {
    constats.map((constat) => {
      const constatIndex = state.constatsList.findIndex(
        ({ uuid }) => constat.uuid === uuid
      );
      if (constatIndex >= 0) {
        state.constatsList[constatIndex] = constat;
        state.constatsList = [...state.constatsList];
      }
    });
  },
  [ADD_NEW_CONSTAT]: (state, constat) => {
    state.constatsList.push(constat);
  },
  [REMOVE_CONSTAT]: (state, constatUuid) => {
    state.constatsList = [
      ...state.constatsList.filter(({ uuid }) => uuid !== constatUuid),
    ];
  },

  [REMOVE_MANY_CONSTAT]: (state, constatsUuids) => {
    state.constatsList = [
      ...state.constatsList.filter(({ uuid }) => !constatsUuids.includes(uuid)),
    ];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
